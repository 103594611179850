<script setup>
import { ref, watch, computed } from 'vue';
import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { pointShortName, renderHeight, computeHeight, getIconImage, renderTimeAgo } from '../Helpers.js';

const settings = settingsStore();
const app = appStateStore();
	
const test = ref();
const show = ref(false);

const labels = ref([]);
var reloadTimer = null;

// function filteredLabels(time) {
// 	console.log(app.thermalHeightsMetaData?.max);
// 	if (!app.thermalHeightsMetaData) return labels.value;

// 	let newLables = [];
// 	for (let i=0; i<labels.value.length; i++) {
// 		console.log(labels.value[i].height + ' vs ' + app.thermalHeightsMetaData.max);
// 		if (labels.value[i].height <= app.thermalHeightsMetaData.max) {
// 			console.log('adding ' + labels.value[i].height);
// 			newLables.push(labels.value[i]);
// 		}
// 	}
// 	return labels.value;
// };

// refresh two minutes 
function startTimer() {

	if (!settings.showThermalHeights) {
		 clearTimeout(reloadTimer);
		 return;
	}

	// console.log('starting timer');
	reloadTimer = setTimeout(() => { 
		loadClimbsMetadata();
	}, 2*60*1000);

}


// process for heatmap
function loadClimbsMetadata() {
	//console.log('Loading loadClimbsMetadata');

	// get the meta data about the current hex data
	axios.get('https://meta.puretrack.io/thermal-heights-meta.json').then(function(response) {
		app.thermalHeightsMetaData = response.data;
		//console.log(app.thermalHeightsMetaData);
	}).catch(function (error) {
		console.log('Hex Grid Error:');
		console.log(error);
	});

	startTimer(); // reload
}


for (var i=0; i<app.colourRange.length; i++) {
	// fillColour.push(app.colourRange[i]);
	// fillColour.push(Math.round((i+1)*304.8));
	labels.value.push({'height': Math.ceil((i+2)*304.8), 'colour': app.colourRange[i]});
}

labels.value = labels.value.reverse();


if (!app.thermalHeightsMetaData) app.thermalHeightsMetaData = {'max':3048,'min':0};

function shortHeight(value) {
	return Math.round(computeHeight(value, settings.altitudeUnits)/100)*100;
}

setTimeout(function() {
	show.value=true;
}, 2000);

// start the main timer
loadClimbsMetadata();

</script>


<style>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>


<template>
	<Transition>
		<div class="flex flex-col w-fit rounded bg-white pointer-events-auto " v-if="labels && show">
			<template v-for="label in labels" :key="label.id">
					<div class="text-black px-1 py-1 text-xs font-bold opacity-70 first:rounded-t last:rounded-b"

						:style="'background-color: ' + label.colour" 
						v-if="label.height <= app.thermalHeightsMetaData.max+306">
						{{shortHeight(label.height)}}
					</div>
			</template>

				<!-- <div class="text-black px-1 py-1 text-xs font-bold opacity-70 rounded-tl last:rounded-bl bg-white">
						<span v-show="settings.altitudeUnits=='feet'">ft</span>
						<span v-show="settings.altitudeUnits=='meters'">m</span>
				</div> -->
			<!-- <div class="text-black px-1 py-1 text-xs font-bold opacity-70 first:rounded-tl last:rounded-bl" 
			:style="'background-color: ' + label.colour" 
			v-for="label in filteredLabels(app.currentTime)"
			>
				{{shortHeight(label.label)}}
			</div> -->
		</div>
	</Transition>


</template>

